import React from 'react'
import List from './list'

function Employee
() {
  return (
    <div>
        <List/>
    </div>
  )
}

export default Employee
