export const ACTION_TYPE = {
  // Login user constants
  LOGIN_USER_SAGA: "login_user_saga",
  LOGIN_USER_PENDING: "login_user_pending",
  LOGIN_USER_FULFILLED: "login_user_fullfilled",
  LOGIN_USER_REJECTED: "login_user_rejected",
  LOGIN_RETURN_INITIAL_STATE: "login_return_initial_state",

  FILTER_DATA_PROJECT: "FILTER_DATA_PROJECT",  
  FILTER_DATA_INITIAL_STATE: "FILTER_DATA_INITIAL_STATE",  


  // project

  GET_LIST_PROJECT_SAGA: "GET_LIST_PROJECT_SAGA",
  GET_LIST_PROJECT_PENDING: "GET_LIST_PROJECT_PENDING",
  GET_LIST_PROJECT_FULFILLED: "GET_LIST_PROJECT_FULFILLED",
  GET_LIST_PROJECT_REJECTED: "GET_LIST_PROJECT_REJECTED",
  GET_LIST_PROJECT_INITIAL_STATE: "GET_LIST_PROJECT_INITIAL_STATE",

  CREATE_PROJECT_SAGA: "CREATE_PROJECT_SAGA",
  CREATE_PROJECT_PENDING: "CREATE_PROJECT_PENDING",
  CREATE_PROJECT_FULFILLED: "CREATE_PROJECT_FULFILLED",
  CREATE_PROJECT_REJECTED: "CREATE_PROJECT_REJECTED",
  CREATE_PROJECT_INITIAL_STATE: "CREATE_PROJECT_INITIAL_STATE",

  UPDATE_PROJECT_SAGA: " UPDATE_PROJECT_SAGA",
  UPDATE_PROJECT_PENDING: "UPDATE_PROJECT_PENDING",
  UPDATE_PROJECT_FULFILLED: "UPDATE_PROJECT_FULFILLED",
  UPDATE_PROJECT_REJECTED: "UPDATE_PROJECT_REJECTED",
  UPDATE_PROJECT_INITIAL_STATE: "UPDATE_PROJECT_INITIAL_STATE",

  DELETE_PROJECT_SAGA: "DELETE_PROJECT_SAGA",
  DELETE_PROJECT_PENDING: "DELETE_PROJECT_PENDING",
  DELETE_PROJECT_FULFILLED: "DELETE_PROJECT_FULFILLED",
  DELETE_PROJECT_REJECTED: "DELETE_PROJECT_REJECTED",
  DELETE_PROJECT_INITIAL_STATE: "DELETE_PROJECT_INITIAL_STATE",

  

   
};
