import React from 'react'
import List from './list'

function Articletype
() {
  return (
    <div>
        <List/>
    </div>
  )
}

export default Articletype
